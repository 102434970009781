var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-card-title",
        [
          _c("v-img", {
            staticClass: "mb-4",
            attrs: { src: require("@/assets/Dropbox.svg"), "max-width": "100" }
          })
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          !_vm.isDropboxLinked
            ? _c(
                "div",
                [
                  _c("p", [
                    _vm._v(
                      "To enable Dropbox synchronization in Nuvolos, give access to the nuvolos.cloud application:"
                    )
                  ]),
                  _c(
                    "v-btn",
                    { attrs: { href: _vm.redirectURI, color: "secondary" } },
                    [_vm._v("Enable access")]
                  )
                ],
                1
              )
            : _c(
                "v-alert",
                { staticClass: "mt-4", attrs: { type: "success" } },
                [
                  _vm._v(
                    " You have enabled access to your Dropbox account for nuvolos.cloud. "
                  )
                ]
              ),
          _c("p", { staticClass: "caption mt-4" }, [
            _c(
              "a",
              {
                staticStyle: { "text-decoration": "none" },
                attrs: {
                  href:
                    "https://docs.nuvolos.cloud/getting-started/work-with-files/dropbox-synchronization",
                  target: "_blank"
                }
              },
              [_vm._v("How does this work?")]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }