<template>
    <v-card flat>
        <v-card-title>
            <v-img src="@/assets/Dropbox.svg" max-width="100" class="mb-4" />
        </v-card-title>
        <v-card-text>
            <div v-if="!isDropboxLinked">
                <p>To enable Dropbox synchronization in Nuvolos, give access to the nuvolos.cloud application:</p>
                <v-btn :href="redirectURI" color="secondary">Enable access</v-btn>
            </div>
            <v-alert type="success" v-else class="mt-4">
                You have enabled access to your Dropbox account for nuvolos.cloud.
            </v-alert>
            <p class="caption mt-4">
                <a href="https://docs.nuvolos.cloud/getting-started/work-with-files/dropbox-synchronization" target="_blank" style="text-decoration: none"
                    >How does this work?</a
                >
            </p>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    data() {
        return {
            isDropboxLinked: false
        }
    },
    computed: {
        redirectURI() {
            const callbackURL = this.requestURI
            const clientID = '5xnkt28kw0sv2nw'
            return `https://www.dropbox.com/oauth2/authorize?client_id=${clientID}&redirect_uri=${callbackURL}&response_type=code&token_access_type=offline`
        },
        requestURI() {
            return location.toString().replace(location.search, '')
        }
    },
    mounted() {
        this.$axios.get('/tokens/dropbox').then(r => {
            if (r && r.data) {
                this.$data.isDropboxLinked = true
            }
        })
        if (this.$route.query && this.$route.query.code) {
            this.$axios
                .post('/tokens/dropbox', { code: this.$route.query.code, request_uri: this.requestURI })
                .then(r => {
                    this.$data.isDropboxLinked = true
                })
                .catch(e => {
                    this.$store.dispatch('showSnackBar', { snackBarText: 'Linking Dropbox failed. Please try again later.', snackBarIcon: 'error' })
                })
        }
    }
}
</script>
